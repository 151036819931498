import React, { useState, useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';
import Shortcuts from './Shortcuts';
import ShortcutPopup from './ShortcutPopup';
import { getApiUrl} from './apiUrl';
import axios from 'axios';

const Home = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user } = useUser();
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
const apiUrl = getApiUrl();
  const gridRows = 4;
  const gridColumns = 4;

  useEffect(() => {
    checkAdminStatus();
  }, [user]);

  // Funkcja do znajdowania wolnej pozycji
  const findFreePosition = (existingPositions) => {
    for (let y = 0; y < gridRows; y++) {
      for (let x = 0; x < gridColumns; x++) {
        if (!existingPositions.some(pos => pos.x === x && pos.y === y)) {
          return { x, y };
        }
      }
    }
    return { x: 0, y: 0 };
  };
  const checkAdminStatus = async () => {
    if (!user) {
      setIsAdmin(false);
      return;
    }

    try {
      const userId = user.userId;
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.get(`${apiUrl}checkAdmin/${user.userId}`, {
        headers: headers
      });

      const data = response.data;

      if (response.status === 200 && response.data.isAdmin) {
        setIsAdmin(true);

      } else {
        console.error(`Error checking admin status: ${data.message}`);
      }
    } catch (error) {
      console.error(`Error checking admin status: ${error.message}`);
    }
  };

  // Inicjalizacja skrótów
  const initializeShortcuts = () => {
    const savedShortcuts = localStorage.getItem('shortcuts');
    if (savedShortcuts) {
      return JSON.parse(savedShortcuts);
    } else {
      return [
        { id: 1, label: t('scaninfo'), icon: 'scan', link: '/app/scanner', position: findFreePosition([]) },
        { id: 2, label: t('searchinfo'), icon: 'search', link: '/app/search', position: findFreePosition([{ x: 0, y: 0 }]) },
        { id: 3, label: t('ainfo'), icon: 'image', link: '/app/analyze', position: findFreePosition([{ x: 0, y: 0 }, { x: 1, y: 0 }]) },
        { id: 4, label: t('listview'), icon: 'bookmark', link: '/app/listview', position: findFreePosition([{ x: 0, y: 0 }, { x: 1, y: 0 }]) },
      ];
    }
  };

  const [shortcuts, setShortcuts] = useState(initializeShortcuts);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleAddShortcut = (shortcut) => {
    const newPosition = findFreePosition(shortcuts.map(s => s.position));
    const newShortcut = {
      ...shortcut,
      id: shortcuts.length + 1,
      position: newPosition,
    };
    setShortcuts([...shortcuts, newShortcut]);
    setIsPopupOpen(false);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('welcome')}</h1>
        <p><b>{t('quicksteps')}</b></p>

        <Shortcuts shortcuts={shortcuts} setShortcuts={setShortcuts} isEditing={isEditing} />

        <button onClick={handleEditToggle}>
          {isEditing ? t('done') : t('edit')}
        </button>

      

        {isPopupOpen && (
          <ShortcutPopup
            availableShortcuts={[
                     { id: 5, label: t('scaninfo'), icon: 'scan', link: '/app/scanner' },
        { id: 6, label: t('searchinfo'), icon: 'search', link: '/app/search'},
        { id: 7, label: t('ainfo'), icon: 'image', link: '/app/analyze'},
        { id: 8, label: t('listview'), icon: 'bookmark', link: '/app/listview'},
            ]}
            onAddShortcut={handleAddShortcut}
            onClose={handleClosePopup}
          />
        )}

{isEditing && (
          <button onClick={handleOpenPopup}>
            {t('addButton')}
          </button>
        )}
        <div className='admin-options'>
          {isAdmin && (
            <>
              <Link to='/app/add-product'>
                <p><IonIcon name='add-circle-outline' /> {t('addproductinfo')}</p>
              </Link>
              <Link to='/app/userslist'>
                <p><IonIcon name='people-outline' /> {t('manageUsers')}</p>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
