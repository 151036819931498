import React, { useState } from 'react';
import './CalcView.css';
import { toast  } from 'react-toastify';
import axios from 'axios';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from './apiUrl';
import IonIcon from '@reacticons/ionicons';

const CalcView = () => {
  const [carbs, setCarbs] = useState('');
  const [proteins, setProteins] = useState('');
  const [fats, setFats] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [productName, setProductName] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const apiUrl = getApiUrl();

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateFPU = (protein,fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);

  const addToMeal = async () => {
    if (!user) {
      toast.error(t('mustBeLoggedIn'));
      navigate('/app/login');
      return;
    }
  
    // Validate the inputs
    if (!productName.trim() || !carbs.trim() || !proteins.trim() || !fats.trim()) {
      toast.error(t('allFieldsRequired'));
      return;
    }
    
    try {
      const productId = generateRandomNumber();
      const token = user.token; // Get JWT token
      
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.post(
        `${apiUrl}addProductToList`,
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: productName,
            nutriments: {
              carbohydrates_100g: carbs || "0",
              proteins_100g: proteins || "0",
              fats_100g: fats || "0",
            },
          }),
        },
        {
          headers: headers
        }
      );
  
      const { success, message } = response.data;
  
      if (success) {
        toast.success(t('addedToMeal', { productName }));
      } else {
        toast.error(`${t('errorOccurred')}: ${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  
    setShowPopup(false);
    setProductName('');
    setCarbs('');
    setProteins('');
    setFats('');
  };
  



  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('calculateExchange')}</h1>
        <input
          type="number"
          value={carbs}
          onChange={(e) => setCarbs(e.target.value)}
          placeholder={t('enterCarbs')}
        />
          <input
          type="number"
          value={[proteins]}
          onChange={(e) => setProteins(e.target.value)}
          placeholder={t('enterProteins')}
        />
          <input
          type="number"
          value={fats}
          onChange={(e) => setFats(e.target.value)}
          placeholder={t('enterFats')}
        />

        <p><IonIcon name='flash' className='text' size={20} /> {t('wwPer100g')}:</p>
        <h1>{calculateCU(carbs)}</h1>
        <p> <IonIcon name='flash-outline' className='text' size={20} />{t('wbtPer100g')}:</p>
        <h1>{calculateFPU(proteins,fats)}</h1>
        <button className='btnaddproduct' onClick={() => setShowPopup(true)}>
          {t('savecalc')}
        </button>
        {showPopup && (
          <div className="popup">
            <h2>{t('enterProductInfo')}</h2>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder={t('productName')}
            />
             <div className="social-media">
                  <button className="search-link" onClick={addToMeal}>
                    <IonIcon name="bookmark"  />
                  </button>
                  <button className='search-link' disabled>
                    <IonIcon name="restaurant"  />
                  </button>
                </div>
          </div>
        )}
      </div>
   
    </div>
  );
};

export default CalcView;
