// VideoCard.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import imageforvideo from './imageforvideo.jpg';
import './VideoCard.css';
import videoFile from './familyVideo.mp4';

const VideoCard = () => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.useRef(null);

  const handleVideoClick = () => {
    if (!isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <section className="details-vc-container">
        
     
      <div className="dt-vc-text-section">
        <div className="details-vc-title">
          <h1>{t('diabetes')} <span className="dc">DiabCalc</span> {t('diabetes2')}</h1>
        </div>
        <br />
        <p className="details-vc-description">{t('detailsdiab')}</p>
      </div> 
      <div className="dt-vc-image-section">
        <video
          ref={videoRef}
          className="video-player"
          controls={false}
          onClick={handleVideoClick}
          poster={imageforvideo} // Fallback image if video doesn't load
          preload="metadata"
        >
         <source src={videoFile} type="video/mp4" />

          Twoja przeglądarka nie wspiera odtwarzania wideo.
        </video>
      </div>
    </section>
  );
};

export default VideoCard;
