import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AddProductMealPopup.css';

const GramsInputPopup = ({ productName, onClose, onAdd }) => {
  const [grams, setGrams] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (grams) {
      onAdd(productName, grams);
    }
  };

  return (
    <div className="popup-overlay grams">
      <div className="popup-container ">
        <div className="popup-header">
          <h2>{t('addpr')} {productName}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <div className="popup-body">
          <form onSubmit={handleSubmit}>
            <label>
              {t('grams')}
              <input
                type="number"
                value={grams}
                onChange={(e) => setGrams(e.target.value)}
                required
              />
            </label>
            <div className="popup-buttons">
          
              <button type="submit" className='standard-button'>{t('add')}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GramsInputPopup;
