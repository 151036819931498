import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import IonIcon from '@reacticons/ionicons';
import './MealDetails.css';
import AddProductPopup from './AddProductMealPopup';
import { getApiUrl } from './apiUrl';

const MealDetails = () => {
  const { t } = useTranslation();
  const { mealid } = useParams();
  const [meal, setMeal] = useState(null);
  const [products, setProducts] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const apiUrl = getApiUrl();
  const fetchMealDetails = useCallback(async () => {
    try {
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const userId = user.userId;
      const response = await axios.get(`${apiUrl}meal/${mealid}/${userId}`, { headers });

      if (response.data.success) {
        setMeal(response.data.meal);
        setProducts(response.data.products);
        updateMealWW(response.data.products); // Dodane obliczenie WW przy załadowaniu danych
      } else {
        console.error('Failed to fetch meal details:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    }
  }, [mealid, user]);

  useEffect(() => {
    fetchMealDetails();
  }, [fetchMealDetails]);

  const calculateTotalWW = (products) => {
    return products.reduce((total, product) => {
      const productData = JSON.parse(product.json);
      const productWW = productData.nutriments.carbohydrates_100g * (product.grams / 100) / 10;
      return total + productWW;
    }, 0).toFixed(1);
  };

  const updateMealWW = (products) => {
    const updatedWW = products.length > 0 ? calculateTotalWW(products) : '?';
    setMeal((prevMeal) => ({
      ...prevMeal,
      ww: updatedWW
    }));
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const deleteProduct = async (productId) => {
    try {
      const token = user.token;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      //not working 
      const response = await axios.delete('/removeProduct', {
        headers,
        data: {
          mealid,
          userid: user.userId,
          productId,
        },
      });

      if (response.data.success) {
        const updatedProducts = products.filter(product => product.id !== productId);
        setProducts(updatedProducts);
        updateMealWW(updatedProducts);
      } else {
        console.error('Failed to delete product:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    }
  };

  const showAddProductMenu = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  if (!meal) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="container">
      <div className="meal-details-header">
        <h1>{meal.mealname}</h1>
        <button className="edit-button" onClick={toggleEditMode}>
          <IonIcon name="create-outline" size={24} />
        </button>
      </div>
      <div className="meal-details-summary">
   
        <div>{t('exchangeCount')}: {products.length > 0 ? meal.ww : '?' } WW</div>
      </div>
      <div className="meal-details-circle">
        <div className="circle">
          <span>{products.length > 0 ? meal.ww : '?' } WW</span>
        </div>
      </div>
      <div className="meal-details-products">
        <h2>{t('products')}</h2>
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product.id}
              className={`product-item ${editMode ? 'draggable' : ''}`}
            >
              <div className="product-name">{product.product_name}</div>
              <div className="product-info">
                <div>{t('amountOfGrams')}: {product.grams}g</div>
                <div>{(JSON.parse(product.json).nutriments.carbohydrates_100g * (product.grams / 100) / 10).toFixed(1)} WW</div>
              </div>
              {editMode && (
                <button className="delete-product-button" onClick={() => deleteProduct(product.id)}>
                  {t('delete')}
                </button>
              )}
            </div>
          ))
        ) : (
          <p>{t('noProducts')}</p>
        )}
      </div>
      {editMode && (
        <button className="add-product-button" onClick={showAddProductMenu}>
          {t('addProduct')}
        </button>
      )}
      <AddProductPopup isVisible={isPopupVisible}   user={user} onClose={closePopup} />
    </div>
  );
};

export default MealDetails;
