import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './AddProductMealPopup.css';
import { Link } from 'react-router-dom';
import GramsInputPopup from './GramsInputPopup';
import { getApiUrl } from './apiUrl';

const AddProductMealPopup = ({ isVisible, onClose, addProductToMeal, user }) => {
  const { t } = useTranslation();
  const [standardRecommendations, setStandardRecommendations] = useState([]);
  const [showGramsPopup, setShowGramsPopup] = useState(false);
  const [currentProduct, setCurrentProduct] = useState('');
  const apiUrl = getApiUrl();

  useEffect(() => {
    if (isVisible) {
      fetchStandardRecommendations();
    }
  }, [isVisible]);

  const fetchStandardRecommendations = async () => {
    try {
      const response = await axios.get(`${apiUrl}recommendations`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      });
      if (response.data.success) {
        setStandardRecommendations(response.data.products);
      } else {
        console.error('Failed to fetch standard recommendations:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while fetching standard recommendations:', error);
    }
  };

  const handleAddRecommendationToMeal = async (productName, grams) => {
    try {
      const response = await axios.post(`${apiUrl}add_to_meal`, {
        mealid: 'your_meal_id_here',
        productName: productName,
        grams: grams
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      });

      if (response.data.success) {
        console.log(`Added ${productName} to the meal`);
        addProductToMeal(productName, grams);
        setShowGramsPopup(false);
        onClose();
      } else {
        console.error('Failed to add product to the meal:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while adding product to the meal:', error);
    }
  };

  const openGramsPopup = (productName) => {
    setCurrentProduct(productName);
    setShowGramsPopup(true);
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>{t('addProductPopup.title')}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <div className="popup-body">
          {standardRecommendations.length > 0 && (
            <div>
              <h3>{t('addProductPopup.standardRecommendations')}</h3>
              <div className="recommendations">
                {standardRecommendations.map((product) => (
                  <button
                    key={product.id}
                    className="recommendation-button"
                    onClick={() => openGramsPopup(product.product_name)}
                  >
                    {product.product_name}
                  </button>
                ))}
              </div>
            </div>
          )}
          <Link className="standard-button" to="/app/search">
            {t('searchNewProductBtn')}
          </Link>
          <Link className="standard-button" to="/app/scanner">
            {t('scanNewProductBtn')}
          </Link>
          <Link className="standard-button" to="/app/calc">
            {t('addNewProductBtn')}
          </Link>
        </div>
      </div>
      {showGramsPopup && (
        <GramsInputPopup
          productName={currentProduct}
          onClose={() => setShowGramsPopup(false)}
          onAdd={handleAddRecommendationToMeal}
        />
      )}
    </div>
  );
};

export default AddProductMealPopup;
