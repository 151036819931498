// Header.js
import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next for translations
import familyImage from './woman.png'; // Image of a happy family
import diabCalcImage from './logo.jpg';
import { Link } from 'react-router-dom'; // DiabCalc image/logo
import './Header.css';
const Header = ({ handleOpenWebVersion }) => {
  const { t } = useTranslation();

  return (
    <header className="header-container">
      <div className="text-section">
    
      <div className="header-title">
          <h1>{t('welcomedc')}</h1>
          <h1 className="dc">DiabCalc</h1>
        </div>
        <br></br>
        <p className="header-description">{t('diabcalcinfo')}</p>
     
        <p className="header-description blue">   <IonIcon name="hardware-chip"  />{t('newinfo')}</p>
       <br></br>
        <h2 className="download-title">{t('pobierz')}</h2>
       <div className='down-bnt'>
        <button className="open-web-btn" onClick={handleOpenWebVersion}>
          <IonIcon name="globe-outline" className="icon" size={50} />
          {t('openweb')}<br />
          <div className='osicons'>
          <IonIcon name="logo-android"  className='osicon' />
          <IonIcon name="logo-apple"  className='osicon' />
          <IonIcon name="logo-microsoft"  className='osicon' />
          </div>
        </button>
        <br></br>
        
        <Link className="open-web-btn" to="https://play.google.com/store/apps/details?id=com.koderteam.diabcalcnative" >
          <IonIcon name="logo-google-playstore" className="icon" size={50} />
          {t('openandroid')}<br />
          <div className='osicons'>
          <IonIcon name="logo-android"  className='osicon' />
      
          </div>
        </Link>
        </div>
      </div>
      <div className="image-section">
        <img src={familyImage} className="family-image" alt="Happy Family" />
      </div>
    </header>
  );
};

export default Header;
