import React from 'react';
import './Ideas.css'; // Ensure you create this CSS file for styling
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';

const Ideas = () => {
  const { t } = useTranslation();

  const goals = [
    { id: 1, title: t('goal1Title'), description: t('goal1Description') , icon:'image' },
    { id: 2, title: t('goal2Title'), description: t('goal2Description'), icon:'restaurant'  },
    { id: 3, title: t('goal3Title'), description: t('goal3Description') , icon:'search' },
    { id: 4, title: t('goal4Title'), description: t('goal4Description') , icon:'construct' },
    // Add more goals as needed
  ];

  return (
    <section className="ideas-container" id="goals-section">
      <h1 className='goalstitle'>{t('goalstitle')}</h1>  
      
      <div className='goals'>
        {goals.map(goal => (
          <div key={goal.id} className="goal-tile">
            <h2 className="goal-title">{goal.title}</h2> 
            <IonIcon size={50} name={goal.icon}></IonIcon>
            <p className="goal-description">{goal.description}</p>
         
          </div>
        ))}
      </div>
    </section>
  );
};

export default Ideas;
