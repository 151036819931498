import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AboutUs.css'; // Add some styles for the landing page
import NavBar from "./NavBar";
import { useTranslation } from 'react-i18next';
import KacperImg from './files/Kacper.jpg';
import PawelImg from './files/Pawel.jpg';
import IonIcon from "@reacticons/ionicons";

import Footer from './Footer';

const AboutUsPage = () => {
  const navigate = useNavigate();
const {t} = useTranslation();
  const handleOpenWebVersion = () => {
    navigate('/app');
  };

  return (
    <section className="about" >
      <NavBar></NavBar>
    
      <div className="about"> 
      <h1 className='ourteam'>{t('ourteam')}</h1>
    <br></br>
        <div className='member'>
       
        <h2>Kacper Sikora</h2>
<img className="imgks" src={KacperImg}></img>
<p>{t('ksopis')}</p>
<div className="social-media">
<Link to="https://www.youtube.com/channel/UC37t0k39bcWRzAreu7bAkSQ"  className="social-link">
        <IonIcon name="logo-youtube"    />
        </Link>
        <Link to="https://github.com/koderhack"  className="social-link">
        <IonIcon name="logo-github"   />
        </Link>
        <Link to="https://www.linkedin.com/in/kacper-sikora-b63ab9260/"  className="social-link">
        <IonIcon name="logo-linkedin"   />
        </Link>
        </div>
</div>
<br></br>
<div className='member'>
<h2>Paweł Bystrzyński</h2>
<img className="imgks" src={PawelImg}></img>
<p>{t('pbopis')}</p>
<div className="social-media">
<Link to="https://stacja4.ignatianum.edu.pl/audycje/magazyn-sportowy-pierwsze-miejsce/"  className="audycja">
        <IonIcon name="radio"  className='icon' size={50} />  {t('audition')}
        </Link>
        </div>

</div>

      </div>

    </section>
  );
};

export default AboutUsPage;
