import React from 'react';
import PropTypes from 'prop-types';

import AboutUs from './AboutUs';
import Header from './Header';
import Details from './Details';
import diabCalcImage from './logo.jpg'; // Zaktualizuj ścieżkę do obrazu
import './LandingPage.css'; // Upewnij się, że ścieżka do CSS jest poprawna
import IonIcon from "@reacticons/ionicons";
import Goals from './Ideas';
import NavBar from './NavBar';
import VideoCard from './VideoCard';
import Media from './Media';
import Partners from './Partnerzy';

const LandingInfo = ({ t, handleOpenWebVersion }) => {
  return (
    <div className='landinginfo'>
   
    <Header id="header" handleOpenWebVersion={handleOpenWebVersion}/>
  <hr/>
  <div id="details-section">
<Details />
</div>

    <hr/>
<div id='goals-section'>
    <Goals />
    </div>
    <hr/>
    <div className='media-section'>
 <Partners/>
</div>
    <div id="video-section">
<VideoCard/>
</div>
<div className='media-section'>
  <Media/>
</div>
<hr/>
    <div id="about-section">
    <AboutUs />
    </div>

    </div>
  );
};

LandingInfo.propTypes = {
  t: PropTypes.func.isRequired,
  handleOpenWebVersion: PropTypes.func.isRequired,
};

export default LandingInfo;
