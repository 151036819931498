import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from './apiUrl';
import './ImageAnalyzer.css';
import IonIcon from '@reacticons/ionicons';
import CameraInterface from './CameraInterface';
import scanningSoundGoodFile from './scansoundgoodai.mp3';
import scanningSoundBadFile from './scansoundbad.mp3';
import scanningSoundQuestionFile from './scansoundquestionai.mp3';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// Constants for video constraints
const videoConstraints = {
  width: { min: window.innerHeight },
  height: { min: window.innerWidth },
  facingMode: 'environment',
};

// LoadingDots component
const LoadingDots = () => (
  <div className="loading-dots">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);


const AnalysisPopup = ({ analysisResult, question, onResponseSubmit, onCorrectionSubmit, onClose }) => {
  const { t } = useTranslation();
  const [userResponse, setUserResponse] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [corrections, setCorrections] = React.useState({});

  const handleResponseChange = (event) => {
    setUserResponse(event.target.value);
  };

  const handleCorrectionChange = (index, field, value) => {
    setCorrections((prevCorrections) => ({
      ...prevCorrections,
      [`${index}_${field}`]: value
    }));
  };

  const handleResponseSubmit = () => {
    setLoading(true);
    onResponseSubmit(userResponse)
      .finally(() => {
        setLoading(false);
        setUserResponse('');
      });
  };

  const handleCorrectionSubmit = () => {
    setLoading(true);
    onCorrectionSubmit(corrections)
      .finally(() => {
        setLoading(false);
      });
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateFPU = (protein, fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };
  
  const totals = analysisResult?.reduce(
    (acc, item) => {
      acc.carbs += item.carbs;
      acc.proteins += item.proteins;
      acc.fats += item.fats;
      acc.count += 1;
      return acc;
    },
    { carbs: 0, proteins: 0, fats: 0, count: 0 }
  );

  return (
    <div className="analysis-popup">
      <button className="close-button" onClick={onClose}>X</button>
      <h2>{t('mealAnalysis')}</h2>
      {analysisResult.length > 0 ? (
        <div className="analysis-details">
          <h3>{t('ingredients')}</h3>
          <table>
            <thead>
              <tr>
                <th>{t('ingredient')}</th>
                <th>{t('carbs')}</th>
                <th>{t('proteins')}</th>
                <th>{t('fats')}</th>
              </tr>
            </thead>
            <tbody>
              {analysisResult.map((item, index) => (
                <tr key={index}>
                  <td>{item.ingredient}</td>
                  <td>{item.carbs} g</td>
                  <td>{item.proteins} g</td>
                  <td>{item.fats} g</td>
                </tr>
              ))}
              <tr>
                <td><strong>{t('total')}</strong></td>
                <td><strong>{totals?.carbs || 0} g</strong></td>
                <td><strong>{totals?.proteins || 0} g</strong></td>
                <td><strong>{totals?.fats || 0} g</strong></td>
              </tr>
            </tbody>
          </table>

          <div className="totals-breakdown">
            <h3>{t('nutritionalBreakdown')}</h3>
            <p><strong>{t('totalCarbs')}:</strong> {totals?.carbs || 0} g</p>
            <p><strong>{t('totalProteins')}:</strong> {totals?.proteins || 0} g</p>
            <p><strong>{t('totalFats')}:</strong> {totals?.fats || 0} g</p>
          </div>

          <h3>{t('diabeticdata')}</h3>
          <p><IonIcon name='flash' className='text-search' />{t('WW')}: {calculateCU(totals?.carbs) || "Cannot calculate"}</p>
          <p><IonIcon name='flash-outline' className='text-search' />{t('fpu')}: {calculateFPU(totals?.proteins, totals?.fats) || "Cannot calculate"}</p>
          <p><IonIcon name='hardware-chip-outline' className='text-search' /> {t('AIdislaimer')}</p>
        </div>
      ) : (
        question && ( // Upewnij się, że pytanie jest wyświetlane, jeśli istnieje
          <div className="question-section">
            <h3>{t('questionFromAssistant')}</h3>
            <p>{question}</p>
            <textarea
              value={userResponse}
              onChange={handleResponseChange}
              placeholder={t('enterYourAnswer')}
            />
            <button onClick={handleResponseSubmit} disabled={loading}>
              {loading ? <LoadingDots /> : t('submit')}
            </button>
          </div>
        )
      )}
    </div>
  );
};



const ImageAnalyzer = () => {
  const { t } = useTranslation();
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [question, setQuestion] = useState(null);
  const [scanningSoundGood, setScanningSoundGood] = useState(new Audio(scanningSoundGoodFile));
  const [scanningSoundBad, setScanningSoundBad] = useState(new Audio(scanningSoundBadFile));
  const [scanningSoundQuestion, setScanningSoundQuestion] = useState(new Audio(scanningSoundQuestionFile));
  const navigate = useNavigate();

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setCapturedImage(imageSrc);
      uploadImage(imageSrc);
    }
  }, [webcamRef]);

  const uploadImage = async (imageSrc) => {
    if (!imageSrc) {
        toast.error(t('noImageCaptured'));
        scanningSoundBad.play();
        return;
    }

    setIsUploading(true);
    setErrorMessage('');

    const formData = new FormData();
    formData.append('image', dataURItoBlob(imageSrc), 'image.png');

    try {
        const apiUrl = `${getApiUrl()}analyze-image`;
        const response = await axios.post(apiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            const { analysis } = response.data;

            // Set question and analysisResult based on the response
            setQuestion(analysis.analysis.question || ""); // Ensure the question is set
            setAnalysisResult(analysis.items || []); // Ensure analysis items are set

            setShowPopup(true); // Show the popup with results
        } else {
            toast.error(t('analysisFailed'));
            setErrorMessage(t('analysisFailed'));
            scanningSoundBad.play();
        }
    } catch (error) {
        console.error(error);
        toast.error(t('serverError'));
        setErrorMessage(t('serverError'));
        scanningSoundBad.play();
    } finally {
        setIsUploading(false);
    }
};

const handleResponseSubmit = async (response) => {
    try {
        const apiUrl = `${getApiUrl()}respond-query`;
        const result = await axios.post(apiUrl, { userResponse: response });

        if (result.status === 200) {
            const { analysis } = result.data;

            // Check if the response indicates success
            if (result.data.success) {
                // Set question and items correctly based on the response
                setQuestion(analysis.analysis.question || ""); // Ensure question is set
                setAnalysisResult(analysis.items || []); // Ensure items are set

                setShowPopup(true); // Show the popup
                toast.success(t('responseSubmitted'));
            } else {
                toast.error(t('responseSubmissionFailed'));
            }
        } else {
            toast.error(t('responseSubmissionFailed'));
        }
    } catch (error) {
        console.error(error);
        toast.error(t('responseSubmissionFailed'));
    }
};




  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const closePopup = () => {
    setShowPopup(false);
    setCapturedImage(null);
    setAnalysisResult(null);
    setErrorMessage('');
    setQuestion(null);
  };


  const handleTopRightButtonClick = (navigate) => {
    // Implement the desired functionality for the button here
    navigate('/');
 // For example, show a toast message
  };


  const handleCorrectionSubmit = async (corrections) => {
    try {
      const apiUrl = `${getApiUrl()}submit-corrections`;
      const result = await axios.post(apiUrl, { corrections });
      if (result.status === 200) {
        toast.success(t('correctionsSubmitted'));
      } else {
        toast.error(t('correctionsSubmissionFailed'));
      }
    } catch (error) {
      toast.error(t('correctionsSubmissionFailed'));
    }
  };

  return (
    <div className='panel'>
    <div className="image-analyzer-container">
      <div className="webcam-container">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          videoConstraints={videoConstraints}
          className="webcam"
        />
   <Link
          className="top-right-button"
         to='/app/tips'
        >
         <IonIcon name='information-outline' />
        </Link>
 

        
      

        <CameraInterface />
        <div className={`bottom-panel ${isUploading ? 'uploading' : ''}`}>
          <button
            onClick={captureImage}
            disabled={isUploading || showPopup}
            className="capture-button"
          >
            {isUploading ? <LoadingDots /> : <IonIcon name='camera-outline' />}
          </button>
        </div>
      </div>

      {showPopup && (
        <AnalysisPopup
          analysisResult={analysisResult}
          question={question}
          onResponseSubmit={handleResponseSubmit}
          onCorrectionSubmit={handleCorrectionSubmit}
          onClose={closePopup}
        />
      )}
    </div>
    </div>
  );
};

export default ImageAnalyzer;




