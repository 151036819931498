import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'; // Dodane dla obsługi tłumaczeń
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext'; // Import the useUser hook
import './LoginPanel.css';
//import { GoogleLogin } from '@react-oauth/google';
import { getApiUrl } from './apiUrl';


// <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
const LoginPanel = () => {
  const { login } = useUser(); // Use the useUser hook
  const { t } = useTranslation(); // Inicjalizacja funkcji tłumaczącej
  const apiUrl = getApiUrl();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};
  const handleLogin = async () => {
    if (!username || !password) {
      toast.error(t('enterUsernameAndPassword'));
      return;
    }

    try {
      const response = await axios.post(`${getApiUrl()}login`, { username, password }, {
        withCredentials: true, // Ensure CORS is handled
      });

      if (response.data.success) {
        toast.success(t('loginSuccess'));

  
        // Use the login function to set user information
        login(response.data.userId, response.data.token);

        navigate('/app');
      } else {
        toast.error(t('loginError'));
      }
    } catch (error) {
      console.error(error.response.data);
      toast.error(t('loginErrorConnection'));
    }
  };

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('login')}</h1><hr/>

        <label>{t('email')}: <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /></label>
        <label>{t('password')}: <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></label>
        <button onClick={handleLogin}>{t('login')}</button>
        <hr></hr>
    
        <p>{t('noAccount')} <Link className="change" to="/app/register">{t('registerHere')}</Link>.</p>
      </div>
    
    </div>
  );
};

export default LoginPanel;
