// Media.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';
import './Details.css';

const Partners = () => {
  const { t } = useTranslation();

  return (
    <section className="media-container">
     
      <h1 className="media-title"> {t('partnersdesc')}<span className="dc">DiabCalc</span></h1>
      <p className="media-description">{t('partnersdc')}</p>

      <div className="media-links">

        <div className="media-card">
          <Link to="https://autopay.pl/" className="media-link">
            <div className="media-image autopay"></div>
            <div className="media-text">Autopay</div>
          </Link>
        </div>
        
        {/* Add more media cards here if needed */}
      </div>
      <hr></hr>
    </section>
  );
};

export default Partners;